<template>
  <h1>Settings</h1>
</template>

<script>
export default {
  name: 'Settings'
};
</script>

<style>
</style>